import "./Home.scss"
import React, { useState } from "react"
import cn from "classnames"
import sunoSrc from "../../assets/icons/logo.svg"
import wingsLeftSrc from "../../assets/images/wings-left.svg"
import wingsRightSrc from "../../assets/images/wings-right.svg"
import { Carousel, CustomRating } from "../../components"

const reviews = [
  {
    author: "William Johnson",
    rating: 5,
    text: "I’ve never, ever written a review for an app, but I am so impressed by this! The AI is so top notch, the lyrics and music that get created in seconds is honestly so so good. It’s been so much fun using this app and I’ve made so many fun and funny songs. Also, I had an error but their support team helped me fix it right away. Thank you!",
  },
  {
    author: "Christopher Cross",
    rating: 5,
    text: "I’ve dabbled in other AI apps/platforms but nothing as seamless and product oriented as AI. Wrote a short song about my hometown and it’s really catchy. Would be a great addition to anyone making family/event movies.",
  },
  {
    author: "Skinny Cavallo",
    rating: 5,
    text: "As a 30+ year song writer I could either be intimidated by software like this or embrace it and use it to help see my creative process differently. I’ve chosen the latter and it’s it’s been an eye opening process.",
  },
  {
    author: "Alex Fox",
    rating: 5,
    text: "I didn’t expect that it would work so good! I hope I will make some good music with this app",
  },
  {
    author: "Lana Davis",
    rating: 5,
    text: "I made a few different songs for my grandbabies. Their faces were priceless when they listened to them. They thought they were on the radio and the sound was talking about them. So good. I’m Pops (that’s me) of the year.",
  },
  {
    author: "Loli.zizo",
    rating: 5,
    text: "OMGGG THIS APP IS REALY GOOD I CANT BELIEVE THE QUALITY ITS RLY AMAZING I LOVE IT SOOOO MUCH THANK U SOO MUCH",
  },
  {
    author: "vi_kulaeva",
    rating: 5,
    text: "I made a song for a wedding anniversary, it’s an amazing gift. The quality of a song is really great, bravo!",
  },
  {
    author: "Jryandixon",
    rating: 5,
    text: "This app is awesome. I can literally create a song about anything in one minute.",
  },
]

declare function fbq(title: string, message: string) : void;

export const Home = (): JSX.Element => {
  const [loaded] = useState(false)

  function btnClick() {
    fbq("track", "InitiateCheckout");
    window.location.href = "https://sunoai.onelink.me/y7g3?af_xp=custom&pid=web_facebook";
  }

  return (
    <div className="home">
      <section
        id="home"
        className={cn("main", {
          notLoaded: !loaded,
        })}
      >
        <div className="main-header-1">
          <div className="logo">
            <img loading="lazy" alt="suno" src={sunoSrc} />
            <p className="img-text">AI Music</p>
          </div>
        </div>

        <h1 className="main-title">
          Welcome to AI Music <br /> & Song Generator
        </h1>

        <div className="main-wings-block">
          <img className="main-wings-block-wings-left-img" loading="lazy" alt="wings-left" src={wingsLeftSrc} />
          <div className="main-wings-block-text">
            <p className="main-wings-block-text-app">#1 App</p>
            <p className="main-wings-block-text-trusted">Trusted by</p>
            <p className="main-wings-block-text-users-count">1,200,000</p>
            <p className="main-wings-block-text-users">USERS</p>
          </div>
          <img className="main-wings-block-wings-right-img" loading="lazy" alt="wings-right" src={wingsRightSrc} />
        </div>

        <button onClick={btnClick} className="main-download-button">
          Try For FREE
        </button>

        <Carousel className="about-content-mobile">
          {reviews.map((review, index) => (
            <div key={index} className="main-reviews">
              <div className="main-reviews-header">
                <span className="white-text">{review.author}</span>
                <CustomRating readonly={true} initialRating={review.rating} />
              </div>
              <p className="grey-text main-reviews-text">{review.text}</p>
            </div>
          ))}
        </Carousel>
      </section>
    </div>
  )
}
