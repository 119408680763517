import React from "react"
import { Rating } from 'react-simple-star-rating'
import starFullSrc from "../../assets/icons/star.svg";
import starEmptySrc from "../../assets/icons/star-empty.svg";

import "./CustomRating.scss"

type Props = {
  initialRating?: number
  readonly?: boolean
}

export const CustomRating = ({ initialRating, readonly }: Props): JSX.Element => {
  return (
    <Rating
      readonly={readonly}
      initialValue={initialRating}
      emptyColor={'#C4CDD5'}
      fillColor={'#EEC200'}
      transition
      size={20}
      emptyIcon={
        <img src={starEmptySrc} width={20} height={20} alt="starEmpty" />
      }
      fillIcon={
        <img src={starFullSrc} width={20} height={20} alt="starFull" />
      }
    />
  )
}
